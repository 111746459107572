@import './assests/mixins.scss';

body {
  margin: 0;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: $gray-lighten-more-more  !important;
  overflow-y: auto !important;
}

.ant-table {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-pagination-simple-pager {
  input {
    border: none !important;
    pointer-events: none !important;
    padding: 3px !important;

  }
}

.ant-pagination-prev {
  margin-inline-end: 0 !important;
}

.searchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  width: 30%;
}

.ant-input-search .ant-input-password .ant-input[type=text]:focus {
  border-width: 0 !important;
  border-color: transparent !important;
  outline: none !important;
  outline-offset: 0 !important;
}

.ant-input-search .ant-input-password .ant-input[type=text]:active {
  border-width: 0 !important;
  border-color: transparent !important;
  outline: none !important;
  outline-offset: 0 !important;
}

.ant-input[type=text]:focus {
  border-width: 0 !important;
  border-color: transparent !important;
  outline: none !important;
  outline-offset: 0 !important;
}

:where(.css-dev-only-do-not-override-1me4733).ant-menu-light.ant-menu-horizontal>.ant-menu-item::after {
  inset-inline: 0 !important;
}

.ant-radio-button-checked {
  background-color: #3f5786;

}

.ant-radio-button-wrapper {
  font-weight: normal;
}

.ant-divider-horizontal.ant-divider-with-text {
  border-color: #f48c2c !important;
  color: #3f5786 !important;
  font-size: 15px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.ant-form-item .ant-form-item-label>label {
  font-weight: normal !important;
}

#zmmtg-root {
  display: none;

  div[feature-type="sharing"] {
    display: none !important;
  }
}

:where(.css-dev-only-do-not-override-18iikkb).ant-float-btn-primary .ant-float-btn-body {
  background-color: $primary;
}

.ant-float-btn-primary .ant-float-btn-body:hover {
  background-color: $primary;
}